<template>
    <div class="baseMain">
        <div class="baseOperate clearfloat">
            <div class="baseOperateOne userTeacherML">
                <el-button type="primary" @click="openAdd" v-if="authorityStatus.add.show">新增</el-button>
            </div>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="当前报名" name="1">
                <div class="baseTable" v-loading="tableLoading">
                    <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData"
                                 ref="publicTableUserStudent">
                        <el-table-column prop="batch_no" label="批次ID"></el-table-column>
                        <el-table-column prop="batch_name" label="批次名称"></el-table-column>
                        <el-table-column prop="plan_name" label="招生计划名称"></el-table-column>
                        <el-table-column prop="begin_time" label="报名开始时间"></el-table-column>
                        <el-table-column prop="end_time" label="报名截止时间"></el-table-column>
                        <el-table-column prop="number" label="招生人数"></el-table-column>
                        <el-table-column label="预录取">
                            <template #default="scope">
                                <span v-if="scope.row.is_pre_inscription == 1">开启</span>
                                <span v-if="scope.row.is_pre_inscription == 0">关闭</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sign_count" label="报名/录取人数">
                            <template #default="scope">
                                <span>{{scope.row.sign_count}} / {{scope.row.admission_count}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="amount" label="项目缴费金额"></el-table-column>
                        <el-table-column prop="refund_limit" label="报名退费时间" width="180">
                            <template #default="scope">
                                <template v-if="scope.row.refund_limit">
                                    <div>
                                        <span>{{scope.row.refund_limit.split(' - ')[0]}}</span> -
                                    </div>
                                    <div>
                                        <span>{{scope.row.refund_limit.split(' - ')[1]}}</span>
                                    </div>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column label="状态">
                            <template #default="scope">
                                <el-tag type="success" v-if="scope.row.status == 1">进行中</el-tag>
                                <el-tag type="warning" v-else-if="scope.row.status == 2">已暂停</el-tag>
                                <el-tag type="warning" v-else-if="scope.row.status == 0">暂未开始</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="报名详情">
                            <template #default="scope">
                                <!--<el-link class="tableButton" type="success" @click.prevent="downBaoming(scope.row)"-->
                                <!--v-if="scope.row.status == 1">-->
                                <!--下载报名二维码-->
                                <!--</el-link>-->
                                <template v-if="authorityStatus.edit.show">
                                    <el-link class="tableButton" type="primary"
                                             @click.prevent="openEdit(scope.row)">
                                        编辑
                                    </el-link>
                                    <el-link class="tableButton" type="warning"
                                             @click.prevent="changeStatus(scope.row,2)"
                                             v-if="scope.row.status == 1">
                                        暂停报名
                                    </el-link>
                                    <el-link class="tableButton" type="success"
                                             @click.prevent="changeStatus(scope.row,1)"
                                             v-else-if="scope.row.status == 2">
                                        继续报名
                                    </el-link>
                                    <el-link class="tableButton" type="danger"
                                             @click.prevent="changeStatus(scope.row,3)">
                                        结束报名
                                    </el-link>
                                </template>
                            </template>
                        </el-table-column>
                    </publicTable>
                </div>
            </el-tab-pane>
            <el-tab-pane label="历史报名" name="2">
                <div class="baseTable" v-loading="tableLoading">
                    <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData"
                                 ref="publicTableUserStudent">
                        <el-table-column prop="batch_no" label="批次ID"></el-table-column>
                        <el-table-column prop="batch_name" label="批次名称"></el-table-column>
                        <el-table-column prop="plan_name" label="招生计划名称"></el-table-column>
                        <el-table-column prop="begin_time" label="报名开始时间"></el-table-column>
                        <el-table-column prop="end_time" label="报名截止时间"></el-table-column>
                        <el-table-column prop="sign_count" label="报名/录取人数">
                            <template #default="scope">
                                <span>{{scope.row.sign_count}} / {{scope.row.admission_count}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="amount" label="缴费金额"></el-table-column>
                        <el-table-column prop="refund_limit" label="报名退费时间"></el-table-column>
                        <el-table-column label="报名详情">
                            <template #default="scope">
                                <!--<el-link class="tableButton" type="primary"-->
                                <!--@click.prevent="openDetail(scope.row)">-->
                                <!--查看-->
                                <!--</el-link>-->
                                <el-link class="tableButton" type="primary"
                                         @click.prevent="openExport(scope.row)">
                                    下载
                                </el-link>
                            </template>
                        </el-table-column>
                    </publicTable>
                </div>
            </el-tab-pane>
        </el-tabs>
        <div class="basePage" style="margin-top: 4px;">
            <publicPage ref="publicPageUserStudent" :publicPageTotal="{total:total}"
                        @pageChange="getTableData"></publicPage>
        </div>
        <!--<div class="signupProcessTitle">-->
        <!--<strong>当前报名</strong>-->
        <!--</div>-->
        <!--<el-divider></el-divider>-->
        <!--<div class="signupProcessNow" v-if="nowSignup == null">-->
        <!--<div class="signupProcessNowText">-->
        <!--<span>当前没有进行中的报名</span>-->
        <!--</div>-->
        <!--<div class="signupProcessNowText2">-->
        <!--<el-button type="primary" @click="openAdd">创建报名</el-button>-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="signupProcessStatistics" v-else>-->
        <!--<div class="signupPSLeft">-->
        <!--<div class="signupPSLeftIn">-->
        <!--<div class="signupPSLeftInTitle">-->
        <!--<span>报名变化趋势</span>-->
        <!--</div>-->
        <!--<div class="signupPSLeftInTitle2">-->
        <!--<el-date-picker size="small" v-model="value1" type="daterange" range-separator="至"-->
        <!--start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>-->
        <!--</div>-->
        <!--<div class="signupPSLeftEchart">-->
        <!--<div class="signupPSLeftEchartIn">-->
        <!--<publicEchart v-if="publicEchartShow" :xAxis="xAxis" :series="series"></publicEchart>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="signupPSRight">-->
        <!--<el-form label-width="150px" label-position="left">-->
        <!--<el-form-item label="批次ID:">-->
        <!--<span>{{nowSignup.project_id}}</span>-->
        <!--</el-form-item>-->
        <!--<el-form-item label="批次名称:">-->
        <!--<span>{{nowSignup.project_id}}</span>-->
        <!--</el-form-item>-->
        <!--<el-form-item label="报名开始时间:">-->
        <!--<span>{{nowSignup.project_id}}</span>-->
        <!--</el-form-item>-->
        <!--<el-form-item label="报名结束时间:">-->
        <!--<span>{{nowSignup.project_id}}</span>-->
        <!--</el-form-item>-->
        <!--<el-form-item label="报名链接:">-->
        <!--<span>{{nowSignup.project_id}}</span>-->
        <!--</el-form-item>-->
        <!--<el-form-item label="报名二维码:">-->
        <!--<span>{{nowSignup.project_id}}</span>-->
        <!--</el-form-item>-->
        <!--<el-form-item label="当前报名总人数(人):">-->
        <!--<span>{{nowSignup.project_id}}</span>-->
        <!--</el-form-item>-->
        <!--<el-form-item label="当前缴费总金额(元):">-->
        <!--<span>{{nowSignup.project_id}}</span>-->
        <!--</el-form-item>-->
        <!--</el-form>-->
        <!--<div class="signupPSRightButton">-->
        <!--<el-button class="signupPSRightButtonOne" type="primary" @click="openEdit">编辑报名信息</el-button>-->
        <!--<el-button class="signupPSRightButtonOne" @click="changeStatus">暂停报名</el-button>-->
        <!--<el-button class="signupPSRightButtonOne" type="primary" @click="changeStatus">截止报名</el-button>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="signupProcessTitle" style="margin-top: 10px;">-->
        <!--<strong>历史报名</strong>-->
        <!--</div>-->
        <el-drawer title="创建报名" v-model="addDialog" :close-on-click-modal="false" :destroy-on-close="true" size="80%">
            <div style="width: 100%;padding-bottom: 20px;">
                <el-steps :active="active" align-center>
                    <el-step title="报名设置" description=""></el-step>
                    <el-step title="报名信息" description=""></el-step>
                    <el-step title="完成并发布" description=""></el-step>
                </el-steps>
            </div>
            <div class="signupProcessDrawerForm" v-if="active == 1">
                <el-form :model="addForm" :rules="addFormRules" ref="addForm" label-width="auto">
                    <el-form-item label="批次名称" prop="batch_name">
                        <el-input v-model="addForm.batch_name "></el-input>
                    </el-form-item>
                    <el-form-item label="报名开始时间" prop="begin_time">
                        <el-date-picker v-model="addForm.begin_time" type="datetime" placeholder="选择日期时间"
                                        value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="报名截止时间">
                        <el-date-picker v-model="addForm.end_time" type="datetime" placeholder="选择日期时间"
                                        value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="缴费项目">
                        <el-select v-model="addForm.project_id" placeholder="请选择" filterable>
                            <el-option v-for="item in project_id_list" :key="item.value" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="报名退款时间">
                        <el-date-picker v-model="addForm.refund_limit" type="datetimerange" range-separator="-"
                                        start-placeholder="开始时间" end-placeholder="结束时间"
                                        value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="招生计划" prop="recruit_id">
                        <el-select v-model="addForm.recruit_id" placeholder="请选择" filterable>
                            <el-option v-for="item in recruit_id_list" :key="item.value" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="招生人数">
                        <el-input-number v-model="addForm.number" controls-position="right" :min="1"></el-input-number>
                    </el-form-item>
                    <el-form-item label="预录取">
                        <el-radio-group v-model="addForm.is_pre_inscription">
                            <el-radio :label="1">开启</el-radio>
                            <el-radio :label="0">关闭</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="报名须知" prop="notice">
                        <el-input type="textarea" v-model="addForm.notice" :autosize="{minRows: 5}"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="signupProcessDrawerForm2" v-if="active == 2">
                <div class="customizeFormLeft">
                    <el-form :model="batchForm" :rules="batchRules" ref="batchForm" label-width="auto">
                        <el-form-item :label="item.cate" v-for="(item,index) in batchForm" :key="index">
                            <el-checkbox-group v-model="addForm.fields">
                                <el-checkbox v-for="item2 in item.list" :key="item2.name" :label="item2.id + ''" border
                                             :disabled="noEditList.indexOf(item2.id+'') != -1">{{item2.name}}
                                </el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="customizeFormRight">
                    <div class="phonePreview">
                        <img src="../../assets/images/手机背景.png" alt="">
                        <div class="phonePreviewHtml nui-scroll">
                            <customizeForm :fieldData="customizeFormData"></customizeForm>
                        </div>
                    </div>
                </div>
                <!--<el-form :model="batchForm" :rules="batchRules" ref="batchForm" label-width="auto">-->
                <!--<template v-for="item in batchForm" :key="item.key">-->
                <!--<el-form-item :label="item.name">-->
                <!--<template v-if="item.type == 'text'">-->
                <!--<el-input v-model="item.def_value" placeholder="请输入姓名" style="width: 200px;"></el-input>-->
                <!--</template>-->
                <!--<template v-if="item.type == 'select'">-->
                <!--<el-select v-model="item.selOne" placeholder="请选择" style="width: 200px;">-->
                <!--<el-option v-for="item2 in item.options" :key="item2.value" :label="item2.label"-->
                <!--:value="item2.value"></el-option>-->
                <!--</el-select>-->
                <!--<el-button type="success" class="formML" @click="openAddSelectMsg(item)">管理数据源-->
                <!--</el-button>-->
                <!--</template>-->
                <!--<el-radio-group v-model="item.is_required" class="formML">-->
                <!--<el-radio-button :label="1">必填</el-radio-button>-->
                <!--<el-radio-button :label="0">非必填</el-radio-button>-->
                <!--</el-radio-group>-->
                <!--<el-radio-group v-model="item.status" class="formML">-->
                <!--<el-radio-button :label="1">显示</el-radio-button>-->
                <!--<el-radio-button :label="0">不显示</el-radio-button>-->
                <!--</el-radio-group>-->
                <!--<el-button type="danger" class="formML" @click="addOneDel(item)">删除</el-button>-->
                <!--</el-form-item>-->
                <!--</template>-->
                <!--<el-form-item label=" ">-->
                <!--<el-button type="primary" @click="openAddOne">新增</el-button>-->
                <!--</el-form-item>-->
                <!--</el-form>-->
            </div>
            <div class="signupProcessDrawerForm" v-if="active == 3">
                <el-result icon="success" title="报名流程发布成功" subTitle="请前往招生计划页面下载二维码扫码报名"></el-result>
                <!--<div class="signupProcessNewMsg">-->
                <!--<el-form label-width="150px" label-position="left">-->
                <!--<el-form-item label="报名链接:">-->
                <!--<div class="signupProcessErweima line-limit-length">-->
                <!--<span>{{successMsg.path}}</span>-->
                <!--<el-button class="signupProcessErweimaButton" type="primary" plain @click="startCopy">-->
                <!--复制链接-->
                <!--</el-button>-->
                <!--</div>-->
                <!--</el-form-item>-->
                <!--<el-form-item label="报名二维码:">-->
                <!--<div class="signupProcessErweima">-->
                <!--<div ref="imageTofile" style="width: 100px;height: 100px;">-->
                <!--&lt;!&ndash;<qrcode-vue :value="codeUrl" :size="codeSize" level="H"/>&ndash;&gt;-->
                <!--<el-image style="width: 100px; height: 100px;cursor: pointer;"-->
                <!--:src="successMsg.path" fit="contain"-->
                <!--:preview-src-list="[successMsg.path]"></el-image>-->
                <!--</div>-->
                <!--<el-button class="signupProcessErweimaButton" type="primary" plain-->
                <!--@click="openSuccessImage">-->
                <!--下载二维码-->
                <!--</el-button>-->
                <!--</div>-->
                <!--</el-form-item>-->
                <!--</el-form>-->
                <!--</div>-->
            </div>
            <div class="signupProcessDrawerForm" style="text-align: center;">
                <!--<el-button v-if="active == 2" class="signupPSRightButtonOne" size="large" style="width: 160px;"-->
                <!--@click="next">上一步-->
                <!--</el-button>-->
                <el-button class="signupPSRightButtonOne" type="primary" size="large" style="width: 160px;"
                           @click="next">
                    <span v-if="active == 1">下一步</span>
                    <span v-if="active == 2">完成并发布</span>
                    <span v-if="active == 3">完成</span>
                </el-button>
            </div>
        </el-drawer>
        <el-drawer title="编辑报名信息" v-model="editDialog" :close-on-click-modal="false" :destroy-on-close="true"
                   size="80%">
            <div class="signupProcessDrawerForm2">
                <div class="customizeFormLeft">
                    <el-form :model="addForm" :rules="addFormRules" ref="addForm" label-width="auto">
                        <el-divider>报名设置</el-divider>
                        <el-form-item label="批次名称" prop="batch_name">
                            <el-input v-model="addForm.batch_name "></el-input>
                        </el-form-item>
                        <el-form-item label="报名开始时间" prop="begin_time">
                            <el-date-picker v-model="addForm.begin_time" type="datetime" placeholder="选择日期时间"
                                            value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="报名截止时间">
                            <el-date-picker v-model="addForm.end_time" type="datetime" placeholder="选择日期时间"
                                            value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="缴费项目">
                            <el-select v-model="addForm.project_id" placeholder="请选择">
                                <el-option v-for="item in project_id_list" :key="item.value" :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="报名退款时间">
                            <el-date-picker v-model="addForm.refund_limit" type="datetimerange" range-separator="-"
                                            start-placeholder="开始时间" end-placeholder="结束时间"
                                            value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="招生计划" prop="recruit_id">
                            <el-select v-model="addForm.recruit_id" placeholder="请选择">
                                <el-option v-for="item in recruit_id_list" :key="item.value" :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="招生人数">
                            <el-input-number v-model="addForm.number" controls-position="right"
                                             :min="1"></el-input-number>
                        </el-form-item>
                        <el-form-item label="预录取">
                            <el-radio-group v-model="addForm.is_pre_inscription">
                                <el-radio :label="1">开启</el-radio>
                                <el-radio :label="0">关闭</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="报名须知">
                            <el-input type="textarea" v-model="addForm.notice" :autosize="{minRows: 5}"></el-input>
                        </el-form-item>
                        <el-divider>报名信息</el-divider>
                        <!--<el-form-item label="学生信息">-->
                        <!--<el-form :model="batchForm" :rules="batchRules" ref="batchForm" label-width="auto">-->
                        <!--<template v-for="item in batchForm" :key="item.key">-->
                        <!--<el-form-item :label="item.name">-->
                        <!--<template v-if="item.type == 'text'">-->
                        <!--<el-input v-model="item.def_value" placeholder="请输入姓名"-->
                        <!--style="width: 200px;"></el-input>-->
                        <!--</template>-->
                        <!--<template v-if="item.type == 'select'">-->
                        <!--<el-select v-model="item.selOne" placeholder="请选择" style="width: 200px;">-->
                        <!--<el-option v-for="item2 in item.options" :key="item2.value"-->
                        <!--:label="item2.label"-->
                        <!--:value="item2.value"></el-option>-->
                        <!--</el-select>-->
                        <!--<el-button type="success" class="formML" @click="openAddSelectMsg(item.id)">-->
                        <!--管理数据源-->
                        <!--</el-button>-->
                        <!--</template>-->
                        <!--<el-radio-group v-model="item.is_required" class="formML">-->
                        <!--<el-radio-button :label="1">必填</el-radio-button>-->
                        <!--<el-radio-button :label="0">非必填</el-radio-button>-->
                        <!--</el-radio-group>-->
                        <!--<el-radio-group v-model="item.status" class="formML">-->
                        <!--<el-radio-button :label="1">显示</el-radio-button>-->
                        <!--<el-radio-button :label="0">不显示</el-radio-button>-->
                        <!--</el-radio-group>-->
                        <!--<el-button type="danger" class="formML">删除</el-button>-->
                        <!--</el-form-item>-->
                        <!--</template>-->
                        <!--<el-form-item label=" ">-->
                        <!--<el-button type="primary" @click="openAddOne">新增</el-button>-->
                        <!--</el-form-item>-->
                        <!--</el-form>-->
                        <!--</el-form-item>-->
                        <el-form-item :label="item.cate" v-for="(item,index) in batchForm" :key="index">
                            <el-checkbox-group v-model="addForm.fields">
                                <el-checkbox v-for="item2 in item.list" :key="item2.name" :label="item2.id + ''" border
                                             :disabled="noEditList.indexOf(item2.id + '') != -1">{{item2.name}}
                                </el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="customizeFormRight">
                    <div class="phonePreview">
                        <img src="../../assets/images/手机背景.png" alt="">
                        <div class="phonePreviewHtml nui-scroll">
                            <customizeForm :fieldData="customizeFormData"></customizeForm>
                        </div>
                    </div>
                </div>
            </div>
            <div class="signupProcessDrawerForm" style="text-align: center;">
                <el-button class="signupPSRightButtonOne" type="primary" size="large" style="width: 160px;"
                           @click="saveEdit">
                    保存
                </el-button>
            </div>
        </el-drawer>
        <el-drawer title="详情" v-model="drawer" :close-on-click-modal="false" :destroy-on-close="true" size="80%">
            <div class="rtDetailSearch baseSearch clearfloat">
                <div class="baseSearchOne">
                    <div class="baseSearchOneLeft">
                        <span>姓名：</span>
                    </div>
                    <div class="baseSearchOneRight">
                        <el-input v-model="detailSearchForm.user_name" placeholder="请输入内容" clearable></el-input>
                    </div>
                </div>
                <div class="baseSearchOne">
                    <div class="baseSearchOneLeft">
                        <span>是否欠费：</span>
                    </div>
                    <div class="baseSearchOneRight">
                        <el-select v-model="detailSearchForm.is_arrears" placeholder="请选择" clearable>
                            <el-option v-for="item in is_arrears_list" :key="item.value" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="baseSearchOne">
                    <div class="baseSearchOneLeft">
                        <span>筛选项目：</span>
                    </div>
                    <div class="baseSearchOneRight">
                        <el-select v-model="detailSearchForm.project_id" placeholder="请选择" clearable>
                            <el-option v-for="item in project_id_list" :key="item.value" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="baseSearchOne">
                    <el-button type="primary" style="margin-left: 20px;" @click="detailSearch">搜索</el-button>
                    <el-button @click="detailCleanAll">清空</el-button>
                </div>
            </div>
            <div class="baseTable" style="margin-top: 10px;">
                <publicTable :publicTableOtherHeight="(detailSearchHeight+16)" :publicTableData="detailTableData">
                    <el-table-column prop="project_name" label="项目名"></el-table-column>
                    <el-table-column prop="class_name" label="组织部门"></el-table-column>
                    <el-table-column prop="card_number" label="学工号"></el-table-column>
                    <el-table-column prop="user_name" label="姓名"></el-table-column>
                    <el-table-column prop="received_price" label="已缴金额"></el-table-column>
                    <el-table-column prop="be_received_price" label="待缴金额"></el-table-column>
                    <el-table-column label="是否欠费">
                        <template #default="scope">
                            {{is_arrears_obj[scope.row.is_arrears]}}
                        </template>
                    </el-table-column>
                </publicTable>
            </div>
            <div class="basePage" style="margin-top: 4px;">
                <publicPage ref="detailPageUserStudent" :publicPageTotal="{total:detailTotal}"
                            @pageChange="getDetailTableData"></publicPage>
            </div>
        </el-drawer>
        <el-dialog title="新增表单项" v-model="addOneDialog" width="600px" :close-on-click-modal="false">
            <el-form :model="addOneForm" :rules="addOneFormRules" ref="addOneForm" label-width="200px">
                <el-form-item label="类型">
                    <el-radio-group v-model="addOneForm.type">
                        <el-radio label="text">输入框</el-radio>
                        <el-radio label="select">下拉框</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="名称">
                    <el-input v-model="addOneForm.name" placeholder="请输入名称" style="width: 200px;"></el-input>
                </el-form-item>
                <el-form-item label="是否必填">
                    <el-radio-group v-model="addOneForm.is_required">
                        <el-radio-button label="1">必填</el-radio-button>
                        <el-radio-button label="0">非必填</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否显示">
                    <el-radio-group v-model="addOneForm.status">
                        <el-radio-button label="1">显示</el-radio-button>
                        <el-radio-button label="0">不显示</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input-number v-model="addOneForm.sort" controls-position="right"></el-input-number>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="saveAddOne">确 定</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog title="管理下拉框数据源" v-model="selectMsgDialog" width="600px" :close-on-click-modal="false">
            <el-form label-width="200px">
                <el-form-item label="内容">
                    <div class="peopleList">
                        <el-tag style="margin: 4px;" v-for="(item,index) in dynamicTags" :key="index" closable
                                :disable-transitions="false" @close="handleClose(index)">
                            {{item}}
                        </el-tag>
                    </div>
                </el-form-item>
                <el-form-item label="新增内容">
                    <el-input v-model="dynamicTagOne" placeholder="请输入内容" style="width: 200px;"></el-input>
                    <el-button type="success" @click="dynamicTagOneAdd" style="margin-left: 10px;">新增</el-button>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="addSelectMsgSave">确 定</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>

    import publicPage from '../../components/publicPage.vue'

    import publicTable from '../../components/publicTable.vue'

    import {businessGet, businessPost, businessDel, businessPut} from '../../api/business.js'

    // import publicEchart from '../../components/publicEchart.vue'

    // import QrcodeVue from 'qrcode.vue'

    import useClipboard from 'vue-clipboard3'

    import {utilsExportExcel, utilsGetAuthority} from '../../utils/utils.js'

    import customizeForm from '../../components/customizeForm.vue'

    export default {
        name: "signupProcess",
        components: {
            publicPage,
            publicTable,
            // publicEchart,
            // QrcodeVue
            customizeForm,
        },
        data() {
            return {
                noEditList: ['1', '2', '3', '5', '4'],
                activeName: '1',
                nowSignup: null,
                tableLoading: false,
                searchHeight: 0,
                tableData: [],
                total: 0,
                selMsg: null,
                xAxis: ['10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00'],
                series: [
                    {
                        name: '近24时',
                        data: [820, 932, 901, 934, 1290, 1330, 1320],
                        type: 'line'
                    },
                    {
                        name: '上24时',
                        data: [920, 632, 501, 434, 290, 130, 1320],
                        type: 'line'
                    },
                ],
                publicEchartShow: true,
                addDialog: false,
                project_id_list: [],
                recruit_id_list: [],
                active: 1,
                addForm: {
                    batch_name: '',
                    begin_time: '',
                    end_time: '',
                    project_id: '',
                    refund_limit: [],
                    recruit_id: '',
                    number: 1,
                    is_pre_inscription: 0,
                    notice: '',
                    fields: [],
                },
                addFormRules: {
                    batch_name: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                    begin_time: [
                        {required: true, message: '该项不能为空', trigger: 'change'},
                    ],
                    end_time: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                    project_id: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                    recruit_id: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                    notice: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                },
                successMsg: null,
                editDialog: false,
                codeSize: 100,
                form: {
                    name: '',
                    startTime: '',
                    end_time: '',
                },
                batchForm: [],
                customizeFormData: [],
                options: [
                    {
                        value: '选项1',
                        label: '黄金糕',
                    },
                    {
                        value: '选项2',
                        label: '双皮奶',
                    },
                    {
                        value: '选项3',
                        label: '蚵仔煎',
                    },
                    {
                        value: '选项4',
                        label: '龙须面',
                    },
                    {
                        value: '选项5',
                        label: '北京烤鸭',
                    },
                ],
                // addOneDialog: false,
                // addOneForm: {
                //     type: '',
                //     name: '',
                //     is_required: '1',
                //     status: '1',
                //     sort: 0,
                // },
                // addOneFormRules: {
                //     type: [
                //         {required: true, message: '该项不能为空', trigger: 'blur'},
                //     ],
                //     name: [
                //         {required: true, message: '该项不能为空', trigger: 'blur'},
                //     ],
                //     is_required: [
                //         {required: true, message: '该项不能为空', trigger: 'blur'},
                //     ],
                //     status: [
                //         {required: true, message: '该项不能为空', trigger: 'blur'},
                //     ],
                // },
                // selectMsgDialog: false,
                // dynamicTagsSelMsg: null,
                // dynamicTags: [],
                // dynamicTagOne: '',
                drawer: false,
                detailSearchForm: {
                    org_id: '',
                    user_name: ''
                },
                detailSearchHeight: 0,
                detail_total_price: {
                    be_received_price: 0,
                    price: 0
                },
                detailTableData: [],
                detailTotal: 0,
                authorityStatus: {
                    add: {
                        method: 'post',
                        url: '/admin/v1/sign_up',
                        show: false,
                    },
                    edit: {
                        method: 'put',
                        url: '/admin/v1/sign_up/{batch_no}',
                        show: false,
                    },
                }
            }
        },
        watch: {
            'addForm.fields'() {
                this.generateFiledMsg();
            }
        },
        created() {
            utilsGetAuthority(this.authorityStatus)
            this.get_project_id_list();
            this.get_recruit_id_list();
            this.$nextTick(() => {
                let baseSearchDom = document.querySelector('.baseOperate');
                let searchHeight = baseSearchDom.offsetHeight;
                this.searchHeight = searchHeight + 55;
                this.getTableData();
            })
        },
        methods: {
            generateFiledMsg() {
                let batchForm = JSON.parse(JSON.stringify(this.batchForm));
                for (let i = batchForm.length - 1; i >= 0; i--) {
                    let item = batchForm[i];
                    for (let j = item.list.length - 1; j >= 0; j--) {
                        let son = item.list[j];
                        if (this.addForm.fields.indexOf((son.id + '')) == -1) {
                            batchForm[i].list.splice(j, 1)
                        }
                    }
                    if (item.list.length == 0) {
                        batchForm.splice(i, 1)
                    }
                }
                this.customizeFormData = batchForm;
            },
            downBaoming(item) {
                window.open(item.qrcode);
            },
            importHandleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 ` + this.importForm.importFileLimit + ` 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            importHandleChange(file, fileList) {
                if (file.status == "ready") {
                    let uid = file.uid
                    const isLt = file.size / 1024 / 1024 / 1 <= 1;
                    if (!isLt) {
                        this.$message.warning("上传文件大小不得大于1MB!");
                        for (var i = 0; i < fileList.length; i++) {
                            if (fileList[i].uid == uid) {
                                fileList.splice(i, 1)
                            }
                        }
                    }
                }
                this.importForm.importUploadList = fileList;
            },
            openExport(val) {
                let url = '/admin/v1/sign_up/archives?is_export=1&batch_no=' + val.batch_no;
                utilsExportExcel(url)
            },
            detailCleanAll() {
                this.detailSearchForm.user_name = '';
                this.detailSearchForm.is_arrears = '';
                this.detailSearchForm.project_id = '';
                this.detailSearch();
            },
            detailSearch() {
                this.$refs.detailPageUserStudent.currentPage = 1;
                this.getDetailTableData();
            },
            getDetailTableData() {
                let url = '/admin/v1/org_report_info?org_id=' + this.selMsg.id + '&page=' + this.$refs.detailPageUserStudent.currentPage + '&page_pre=' + this.$refs.detailPageUserStudent.pageSize;
                for (let k in this.detailSearchForm) {
                    if (this.detailSearchForm[k]) {
                        url = url + '&' + k + '=' + this.detailSearchForm[k];
                    }
                }
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        this.detailTotal = res.data.data.count;
                        this.detailTableData = res.data.data.list;
                        if (res.data.data.total.be_received_price != null) {
                            this.detail_total_price.be_received_price = res.data.data.total.be_received_price;
                        }
                        if (res.data.data.total.price != null) {
                            this.detail_total_price.price = res.data.data.total.price;
                        }
                        if (res.data.data.total.wei_count != null) {
                            this.detail_total_price.wei_count = res.data.data.total.wei_count;
                        }
                        if (res.data.data.total.yi_count != null) {
                            this.detail_total_price.yi_count = res.data.data.total.yi_count;
                        }
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            openDetail(val) {
                this.selMsg = val;
                this.drawer = true;
                this.$nextTick(() => {
                    let baseSearchDom = document.querySelector('.rtDetailSearch');
                    let baseOperateDom = document.querySelector('.rtDetailOperate');
                    let searchHeight = baseSearchDom.offsetHeight + baseOperateDom.offsetHeight;
                    this.detailSearchHeight = searchHeight;
                    this.detailSearchForm.user_name = '';
                    this.detailSearchForm.is_arrears = '';
                    this.detailSearchForm.project_id = this.searchForm.project_id;
                    this.detailSearch();
                })
            },
            openSuccessImage() {
                window.open(this.successMsg.path)
            },
            startCopy() {
                let that = this;
                let msg = this.successMsg.path;
                copy(msg)

                async function copy(Msg) {
                    const {toClipboard} = useClipboard()
                    try {
                        //复制
                        await toClipboard(Msg)
                        that.$message({
                            showClose: true,
                            message: '复制成功',
                            type: 'success'
                        });
                    } catch (e) {
                        //复制失败
                        console.error(e)
                    }
                }
            },
            addSelectMsgSave() {
                let url = '/admin/v1/inputs/' + this.dynamicTagsSelMsg.id;
                let data = {
                    def_value: this.dynamicTags.join('/')
                }
                businessPut(url, data).then(res => {
                    if (res.data.status == 200) {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'success'
                        });
                        this.selectMsgDialog = false;
                        this.getCustomizeFormList();
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            handleClose(index) {
                this.dynamicTags.splice(index, 1);
            },
            dynamicTagOneAdd() {
                if (this.dynamicTagOne != '') {
                    this.dynamicTags.push(this.dynamicTagOne);
                    this.dynamicTagOne = '';
                }
                else {
                    this.$message({
                        showClose: true,
                        message: '请填写内容',
                        type: 'warning'
                    });
                }
            },
            openAddSelectMsg(val) {
                this.dynamicTagsSelMsg = val;
                this.selectMsgDialog = true;
            },
            addOneDel(item) {
                let url = '/admin/v1/inputs/' + item.id;
                let data = {}
                businessDel(url, data).then(res => {
                    if (res.data.status == 200) {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'success'
                        });
                        this.getCustomizeFormList();
                    }
                })
            },
            saveAddOne() {
                let url = '/admin/v1/inputs';
                let data = {
                    type: this.addOneForm.type,
                    name: this.addOneForm.name,
                    is_required: this.addOneForm.is_required,
                    status: this.addOneForm.status,
                    def_value: '',
                    sort: this.addOneForm.sort,
                }
                businessPost(url, data).then(res => {
                    if (res.data.status == 200) {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'success'
                        });
                        this.addOneDialog = false;
                        this.getCustomizeFormList();
                    }
                })
            },
            openAddOne() {
                this.addOneForm.type = '';
                this.addOneForm.name = '';
                this.addOneForm.is_required = '1';
                this.addOneForm.status = '1';
                this.addOneDialog = true;
            },
            changeStatus(val, status) {
                let msg = '';
                if (status == 1) {
                    msg = '继续报名'
                }
                else if (status == 2) {
                    msg = '暂停报名'
                }
                else if (status == 3) {
                    msg = '截至报名'
                }
                this.$confirm('此操作将' + msg + ', 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let url = '/admin/v1/sign_up/' + val.batch_no;
                    let data = {
                        status: status
                    }
                    businessPut(url, data).then(res => {
                        if (res.data.status == 200) {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'success'
                            });
                            this.getTableData();
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                        }
                    })
                }).catch(() => {

                })
            },
            saveEdit() {
                this.$refs.addForm.validate((valid) => {
                    if (valid) {
                        let url = '/admin/v1/sign_up/' + this.selMsg.batch_no;
                        let data = {
                            batch_name: this.addForm.batch_name,
                            begin_time: this.addForm.begin_time,
                            end_time: this.addForm.end_time,
                            project_id: this.addForm.project_id,
                            recruit_id: this.addForm.recruit_id,
                            number: this.addForm.number,
                            is_pre_inscription: this.addForm.is_pre_inscription,
                            notice: this.addForm.notice,
                            fields: this.addForm.fields.join(','),
                        }
                        if (this.addForm.refund_limit && this.addForm.refund_limit.length == 2 && this.addForm.refund_limit[0] && this.addForm.refund_limit[1]) {
                            data.refund_limit = this.addForm.refund_limit.join(' - ')
                        }
                        else {
                            data.refund_limit = ''
                        }
                        businessPut(url, data).then(res => {
                            if (res.data.status == 200) {
                                this.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'success'
                                });
                                this.editDialog = false;
                                this.getTableData();
                            }
                            else {
                                this.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'warning'
                                });
                            }
                        })
                    }
                })
            },
            openEdit(val) {
                this.selMsg = val;
                this.addForm.batch_name = this.selMsg.batch_name;
                this.addForm.begin_time = this.selMsg.begin_time;
                this.addForm.end_time = this.selMsg.end_time;
                if (this.selMsg.project_id == 0) {
                    this.addForm.project_id = ''
                }
                else {
                    this.addForm.project_id = this.selMsg.project_id;
                }
                if (this.selMsg.refund_limit) {
                    this.addForm.refund_limit = [this.selMsg.refund_limit.split(' - ')[0], this.selMsg.refund_limit.split(' - ')[1]]
                }
                else {
                    this.addForm.refund_limit = []
                }
                this.addForm.recruit_id = this.selMsg.recruit_id;
                this.get_recruit_id_list(this.selMsg.recruit_id)
                this.addForm.number = this.selMsg.number;
                this.addForm.is_pre_inscription = this.selMsg.is_pre_inscription;
                this.addForm.notice = this.selMsg.notice;
                this.addForm.fields = [...this.selMsg.fields.split(',')];
                this.getCustomizeFormList();
                this.editDialog = true;
            },
            getCustomizeFormList() {
                let url = '/admin/v1/sign_up/field';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        let newArray = res.data.data;
                        // newArray.forEach(item => {
                        //     if (item.list && item.list.length > 0) {
                        //         item.list.forEach(item2 => {
                        //             if (item2.type == 'select' && item2.def_value != null) {
                        //                 let options = [];
                        //                 let newArray = item2.def_value.split('/');
                        //                 newArray.forEach(item3 => {
                        //                     options.push({
                        //                         value: item3,
                        //                         label: item3
                        //                     })
                        //                 })
                        //                 item2.options = options;
                        //                 item2.selOne = '';
                        //             }
                        //         })
                        //     }
                        // })
                        this.batchForm = newArray;
                        this.generateFiledMsg();
                        this.active = 2;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            next() {
                if (this.active == 1) {
                    this.$refs.addForm.validate((valid) => {
                        if (valid) {
                            // let a = [
                            //     {
                            //         def_value: null,
                            //         id: 7,
                            //         is_required: 1,
                            //         key: "61c988c865b04",
                            //         name: "姓名",
                            //         status: 1,
                            //         type: "text",
                            //     },
                            //     {
                            //         def_value: "一班/二班",
                            //         id: 10,
                            //         is_required: 0,
                            //         key: "61c98a918459a",
                            //         name: "班级",
                            //         status: 0,
                            //         type: "select",
                            //     }
                            // ]
                            this.getCustomizeFormList()
                        }
                    })
                }
                else if (this.active == 2) {
                    let url = '/admin/v1/sign_up';
                    let data = {
                        batch_name: this.addForm.batch_name,
                        begin_time: this.addForm.begin_time,
                        end_time: this.addForm.end_time,
                        project_id: this.addForm.project_id,
                        recruit_id: this.addForm.recruit_id,
                        number: this.addForm.number,
                        is_pre_inscription: this.addForm.is_pre_inscription,
                        notice: this.addForm.notice,
                        fields: this.addForm.fields.join(','),
                    }
                    if (this.addForm.refund_limit.length == 2 && this.addForm.refund_limit[0] && this.addForm.refund_limit[1]) {
                        data.refund_limit = this.addForm.refund_limit.join(' - ')
                    }
                    businessPost(url, data).then(res => {
                        if (res.data.status == 200) {
                            this.successMsg = res.data.data;
                            this.getTableData();
                            this.active = 3;
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                        }
                    })
                }
                else if (this.active == 3) {
                    this.addDialog = false;
                }
            },
            openAdd() {
                this.active = 1;
                this.addForm.batch_name = '';
                this.addForm.begin_time = '';
                this.addForm.end_time = '';
                this.addForm.project_id = '';
                this.addForm.refund_limit = [];
                this.addForm.recruit_id = '';
                this.addForm.number = 1;
                this.addForm.is_pre_inscription = 0;
                this.addForm.notice = '';
                this.addForm.fields = this.noEditList;
                this.addDialog = true;
            },
            getTableData() {
                let url = '/admin/v1/sign_up?type=' + this.activeName + '&page=' + this.$refs.publicPageUserStudent.currentPage + '&page_pre=' + this.$refs.publicPageUserStudent.pageSize;
                this.tableLoading = true;
                businessGet(url).then(res => {
                    this.tableLoading = false;
                    if (res.data.status == 200) {
                        this.total = res.data.data.count;
                        this.tableData = res.data.data.list;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            handleClick(tab, event) {
                console.log(tab, event);
                this.tableData = [];
                this.$refs.publicPageUserStudent.currentPage = 1;
                this.getTableData()
            },
            get_recruit_id_list(val) {
                console.log(val);
                let url = '/admin/v1/recruit_plan?page=1&page_pre=500';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        let newArray = [];
                        res.data.data.list.forEach(item => {
                            // if (item.sign_up == null || item.plan_id == val) {
                            //     let a = {
                            //         value: item.plan_id,
                            //         label: item.name
                            //     }
                            //     newArray.push(a);
                            // }
                            let a = {
                                value: item.plan_id,
                                label: item.name
                            }
                            newArray.push(a);
                        })
                        this.recruit_id_list = newArray;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            get_project_id_list() {
                let url = '/admin/v1/projects?page=1&page_pre=500&is_signup=1';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        let newArray = [];
                        res.data.data.list.forEach(item => {
                            let a = {
                                value: item.id,
                                label: item.name
                            }
                            newArray.push(a);
                        })
                        this.project_id_list = newArray;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
        },
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

    .signupProcessTitle {
        width: 100%;
        box-sizing: border-box;
        padding-top: 14px;
    }

    .signupProcessNow {
        width: 100%;
        height: 178px;
        box-sizing: border-box;
        background: rgba(249, 250, 252, 1);
        border: 1px solid rgba(221, 225, 229, 1);
        border-radius: 4px;
    }

    .signupProcessNowText {
        width: 100%;
        box-sizing: border-box;
        color: rgba(112, 112, 112, 1);
        font-size: 14px;
        margin-top: 52px;
        text-align: center;
    }

    .signupProcessNowText2 {
        width: 100%;
        box-sizing: border-box;
        margin-top: 26px;
        text-align: center;
    }

    .signupProcessStatistics {
        width: 100%;
        height: 456px;
        box-sizing: border-box;
        padding-right: 404px;
        position: relative;
    }

    .signupPSLeft {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .signupPSLeftIn {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background: rgba(249, 250, 252, 1);
        border: 1px solid rgba(221, 225, 229, 1);
        border-radius: 4px;
        position: relative;
    }

    .signupPSLeftInTitle {
        width: 100%;
        height: 24px;
        box-sizing: border-box;
        padding: 0 10px;
        position: absolute;
        top: 10px;
        left: 0;
        line-height: 24px;
    }

    .signupPSLeftInTitle2 {
        width: 100%;
        height: 24px;
        box-sizing: border-box;
        padding: 0 10px;
        position: absolute;
        top: 44px;
        left: 0;
    }

    .signupPSLeftEchart {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 78px 10px 10px 10px;
    }

    .signupPSLeftEchartIn {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background-color: #fff;
    }

    .signupPSRight {
        width: 384px;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        background: rgba(249, 250, 252, 1);
        border: 1px solid rgba(221, 225, 229, 1);
        border-radius: 4px;
        position: absolute;
        top: 0;
        right: 0;
    }

    .signupPSRightButton {
        width: 100%;
        text-align: center;
    }

    .signupPSRightButtonOne {
        margin: 0 6px;
    }

    .signupProcessDrawerForm {
        width: 800px;
        margin: 20px auto;
    }

    .signupProcessNewMsg {
        width: 448px;
        height: 203px;
        box-sizing: border-box;
        padding: 20px;
        background: rgba(250, 252, 255, 1);
        border: 1px solid rgba(200, 223, 250, 1);
        border-radius: 10px;
        margin: 0 auto;
    }

    .signupProcessErweima {
        width: 100%;
        box-sizing: border-box;
        padding-right: 120px;
        position: relative;
    }

    .signupProcessErweimaButton {
        position: absolute;
        top: 0;
        right: 0;
    }

    .formML {
        margin-left: 20px;
    }

    .peopleList {
        width: 100%;
        min-height: 32px;
        box-sizing: border-box;
    }

    .signupProcessDrawerForm2 {
        width: 1000px;
        min-height: 720px;
        position: relative;
        margin: 10px auto;
        box-sizing: border-box;
        padding-right: 480px;
        padding-left: 10px;
    }

    .customizeFormLeft {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 30px 0 0 0px;
    }

    .customizeFormRight {
        width: 460px;
        height: 100%;
        box-sizing: border-box;
        padding: 0 10px;
        position: absolute;
        top: 0;
        right: 0;
    }

    .phonePreview {
        width: 440px;
        background-color: #fff;
        position: relative;
    }

    .phonePreview > img {
        width: 100%;
    }

    .phonePreviewHtml {
        width: 270px;
        height: 578px;
        box-sizing: border-box;
        /*background-color: yellow;*/
        position: absolute;
        top: 82px;
        left: 50%;
        transform: translateX(-50%);
        overflow-y: auto;
    }

</style>
